import React, {FunctionComponent} from 'react';

const TechnologicalNews: FunctionComponent = () => (
    <div>
        <div className="row justify-content-center">
            <h3 className="mt-4 text-center">Mon actualité technologique</h3>
        </div>
        <div className="row justify-content-center mt-3">
            <div className="col-xxl-6 col-lg-9 col-md-9 col-12">
                <div className="border rounded-3 shadow py-3 px-3">
                    <ul className="mb-0">
                        <li className="mt-1">
                        Je me forme activement au <b>Software Craftsmanship</b> afin d'approfondir mes connaissances sur les bonnes pratiques de développement, la qualité logicielle, et l'amélioration continue des processus techniques.
                        </li>
                        <li className="mt-1">
                            J'ai initié un projet personnel nommé <a href="https://gitlab.com/charles.garoux/backbone" target="_blank" rel="noreferrer">Backbone</a>, un "starter" pour démarrer rapidement des projets d'application web. Ce projet intègre mes connaissances en <b>Web</b>, <b>Cloud</b>, et <b>DevOps</b>. Les fonctionnalités de ce starter sont détaillées dans <a href="https://gitlab.com/charles.garoux/backbone/-/blob/main/backbone-features.md?ref_type=heads" target="_blank" rel="noreferrer">ce document</a>.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
);

export default TechnologicalNews;